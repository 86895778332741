import authHeader from "./auth-header";
import http from "./httpService";

// Merge the authentication headers with the custom CORS header
function combinedHeaders() {
  return {
    ...authHeader(),
    "Access-Control-Allow-Headers":
      "x-access-token, Origin, X-Requested-With, Content-Type, Accept",
  };
}

export async function getDTCRequestCount() {
  const { data: count } = await http.get("/api/v1/dtcRequests/getCount", {
    headers: combinedHeaders(),
  });
  return count;
}

export async function getDTCRequests() {
  const { data: dtcRequests } = await http.get("/api/v1/dtcRequests/", {
    headers: combinedHeaders(),
  });
  return dtcRequests;
}

export async function getDTCRequestsSinceDate(startDate) {
  const { data: dtcRequests } = await http.get(
    `/api/v1/dtcRequests/sinceDate/${startDate}`,
    {
      headers: combinedHeaders(),
    }
  );
  return dtcRequests;
}

export async function getDTCRequestInfo(dtcRequestId) {
  const { data: dtcRequestInfo } = await http.get(
    "/api/v1/dtcRequests/" + dtcRequestId,
    {
      headers: combinedHeaders(),
    }
  );
  return dtcRequestInfo;
}

export async function getDTCRequestByResellerID(resellerId) {
  const { data: dtcRequests } = await http.get(
    `/api/v1/dtcRequests/getByFKey2/${resellerId}`,
    {
      headers: combinedHeaders(),
    }
  );
  return dtcRequests;
}

export async function getDTCRequestByDemoID(demoId) {
  const { data: dtcRequestInfo } = await http.get(
    `/api/v1/dtcRequests/getByFKey/${demoId}`,
    {
      headers: combinedHeaders(),
    }
  );
  return dtcRequestInfo;
}

export async function updateDTCRequest(dtcRequestId, dtcRequest) {
  const response = await http.put(
    `/api/v1/dtcRequests/${dtcRequestId}`,
    dtcRequest,
    {
      headers: combinedHeaders(),
    }
  );
  return response;
}

export async function createDTCRequest(dtcRequest) {
  const response = await http.post(`/api/v1/dtcRequests/`, dtcRequest, {
    headers: combinedHeaders(),
  });
  return response;
}

export async function removeDTCRequest(dtcRequestId) {
  const response = await http.delete(`/api/v1/dtcRequests/${dtcRequestId}`, {
    headers: combinedHeaders(),
  });
  return response;
}
