import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { createUserActivity } from "../../services/userActivityService";
import { incrementView } from "../../services/demoService";

import authService from "../../services/auth.service";
import http from "../../services/httpService";
import { getDemoInfo } from "../../services/demoService";
import {
  getDTCRequestInfo,
  updateDTCRequest,
  createDTCRequest,
} from "../../services/dtcRequestService";
import { getResellerInfo } from "../../services/resellerService";
import { logData, isDebug } from "../common/helpers";
import Input from "../common/input";

export default function DTCRequestForm() {
  const [fetchedDTCRequest, setFetchedDTCRequest] = useState(null);
  const [fetchedDemo, setFetchedDemo] = useState(null);
  const [fetchedReseller, setFetchedReseller] = useState(null);
  const [types, setTypes] = useState([
    "Admin Time",
    "Business Partner Training",
    "Demo for Business Partner",
    "Demo for End User",
    "Practice for Demo",
    "Setup for Demo",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { dtcRequestId } = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  const { demoId, demoName } = location.state;

  const currentUser = authService.getCurrentUser();
  const isNewDTCRequest = dtcRequestId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewDTCRequest
    ? "Access DTC"
    : "Update DTC Request";
  const demoTitle = isNewDTCRequest ? demoName : fetchedDemo?.name;

  const schema = yup.object().shape({
    productName: yup.string().required().min(4).max(250),
    comments: yup.string().required().min(4),
    activityType: yup
      .string()
      .oneOf([
        "Admin Time",
        "Business Partner Training",
        "Demo for Business Partner",
        "Demo for End User",
        "Practice for Demo",
        "Setup for Demo",
      ]),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState,
  } = useForm({ resolver: yupResolver(schema) });

  const updateViewCount = async () => {
    const incviewResponse = await incrementView(demoId);
    if (isDebug) console.log(incviewResponse);
  };

  const logActivity = async (action) => {
    const newActivity = {};
    newActivity.userId = currentUser.id;
    newActivity.userName = currentUser.userName;
    newActivity.entityType = "Demo";
    newActivity.entityAction = action;
    newActivity.entityName = fetchedDemo.name;
    newActivity.entityId = fetchedDemo.id;
    const actResponse = await createUserActivity(newActivity);
    if (isDebug) console.log(actResponse);
  };

  const handleUpdate = (data) => {
    console.log(`Handling ${submitButtonLabel} for demo ID ${demoId}`);
    console.log("Original data submitted...", data);
    
    logActivity("DTC Access");
    updateViewCount();
    
    let newData = data;
    newData.demoId = parseInt(demoId);
    newData.resellerId = fetchedReseller.id;
    newData.userId = currentUser.id;

    if (!data.endUserName) newData.endUserName = currentUser.fullName;

    if (isDebug) {
      console.log("New data after correction...");
      console.log(newData);
    }

    const addDTCRequest = async () => {
      const updateResponse = await createDTCRequest(newData);
      if (isDebug) {
        console.log("updateResponse: ", updateResponse);
        console.log("updateResponse.status: ", updateResponse.status);
      }
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        console.log("Calling Redirect...");
        console.log("redirect(" + fetchedDemo.ibmDTCurl + ")");
        window.open(fetchedDemo.ibmDTCurl, "_blank");
        navigate(-1);
      }
      if (updateResponse.status >= 400 && updateResponse.status < 500) {
        setErrMsg(updateResponse.data);
        setHasError(true);
      }
    };

    const pushUpdate = async () => {
      newData.id = dtcRequestId;
      const updateResponse = await updateDTCRequest(dtcRequestId, newData);
      if (isDebug) {
        logData(updateResponse);
        logData(updateResponse.status);
      }
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        redirect(fetchedDemo.ibmDTCurl);
      }
      if (updateResponse.status >= 400 && updateResponse.status < 500) {
        setErrMsg(updateResponse.data);
        setHasError(true);
      }
    };

    if (dtcRequestId === "new") {
      addDTCRequest().catch((error) => http.handleError(error));
    } else {
      pushUpdate().catch((error) => http.handleError(error));
    }
  };

  // Runs after initial render
  useEffect(() => {
    setIsLoading(true);

    const pullInfo = async () => {
      const currentDTCRequest = await getDTCRequestInfo(dtcRequestId);
      setFetchedDTCRequest(currentDTCRequest);
      console.log(currentDTCRequest);
    };

    if (!isNewDTCRequest) {
      if (isDebug) logData("Editing existing DTC Request");
      pullInfo().catch(console.error);
    } else {
      if (isDebug) console.log("currentUser", currentUser);
      const pullReseller = async () => {
        const currentReseller = await getResellerInfo(currentUser?.resellerId);
        if (isDebug) console.log("currentReseller", currentReseller);
        setFetchedReseller(currentReseller);
      };

      if (currentUser.resellerId) {
        pullReseller().catch(console.error);
      }
      // need to set Product Name so form is valid
      setValue("productName", demoName, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    const pullDemoInfo = async () => {
      const currentDemo = await getDemoInfo(demoId);
      setFetchedDemo(currentDemo);
      console.log("current Demo:", currentDemo);
    };
    pullDemoInfo().catch(console.error);

    setIsLoading(false);
  }, []);

  // Runs when a reservation is fetched
  useEffect(() => {
    if (typeof fetchedDTCRequest === "object" && fetchedDTCRequest !== null) {
      logData("Fetched DTCRequest ID: " + fetchedDTCRequest.id);
    } else {
      return;
    }

    const pullReseller = async () => {
      const currentReseller = await getResellerInfo(fetchedDTCRequest?.resellerId);
      if (isDebug) logData(currentReseller);
      setFetchedReseller(currentReseller);
    };
    if (fetchedDTCRequest.resellerId) {
      pullReseller().catch(console.error);
    }

    setValue("description", fetchedDTCRequest.description, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("startTime", fetchedDTCRequest.startTime, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("expectedNumOfAttendees", fetchedDTCRequest.expectedNumOfAttendees, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("type", fetchedDTCRequest.type, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("resellerId", fetchedDTCRequest.resellerId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("requestedBy", fetchedDTCRequest.user.id, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("acctNum", fetchedDTCRequest.acctNum, {
      shouldValidate: true,
      shouldDirty: true,
    });
    console.log(fetchedDTCRequest);
  }, [fetchedDTCRequest]);

  return (
    <React.Fragment key={1234}>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <div className="container shadow border border-2 border-light rounded-3 pt-2 mt-2 mb-5">
            <div className="row text-center">
              <div className="col fs-4">
                Access: <span className="fs-3"> {demoTitle} </span>
              </div>
            </div>

            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mb-3">
                <div className="col pt-2">
                  <span className="text-muted">Reseller:</span>
                  <div>
                    {fetchedReseller?.name} ({fetchedReseller?.acctNum})
                  </div>
                </div>
                <div className="col pt-2">
                  <span className="text-muted">Product Name:</span>
                  <div>{demoTitle}</div>
                </div>
                <div className="col">
                  <div className="form-group m-2">
                    <label className="text-muted" htmlFor="activityType">
                      <small>Select DTCRequest type...</small>
                    </label>
                    <select
                      {...register("activityType", {
                        onChange: (e) => {
                          setValue("type", e.target.value);
                        },
                      })}
                      id="type"
                      className="form-select"
                    >
                      <option key="0" value="">
                        {" "}
                      </option>
                      {types.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {formState.errors["activityType"] && (
                      <small>
                        <div className="alert alert-danger">
                          {formState.errors.activityType?.message}
                        </div>
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mt-2">
                <div className="col p-2">
                  <Input
                    register={register}
                    fieldName="endUserName"
                    label="End User Name"
                    errors={formState.errors}
                  />
                </div>
                <div className="col p-2">
                  <Input
                    register={register}
                    fieldName="potentialRevenue"
                    label="Potential Revenue($)"
                    errors={formState.errors}
                  />
                </div>
                <div className="col p-2 me-2">
                  <Input
                    register={register}
                    fieldName="dealReg"
                    label="Deal Registration"
                    errors={formState.errors}
                  />
                </div>
              </div>

              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mt-3">
                <div className="col-12">
                  <div className="form-group m-2">
                    <label className="text-muted" htmlFor="comments">
                      Reason for DTC Access:
                    </label>
                    <textarea
                      {...register("comments")}
                      className="form-control"
                      rows="5"
                    />
                    {formState.errors["comments"] && (
                      <div className="alert alert-danger">
                        {formState.errors["comments"].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center mt-2">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary me-2 mb-2"
                        type="submit"
                        value={submitButtonLabel}
                      />
                      <input
                        className="btn btn-sm btn-danger mb-2"
                        type="button"
                        value="Cancel"
                        onClick={() => navigate(-1)}
                      />
                    </div>
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
} 